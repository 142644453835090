import React from 'react'
import HomePage from 'src/components/Page/Author/Home/container'



const HomePageContainer = ({ location }) => {
    return <HomePage location={location} />
}

export default HomePageContainer

